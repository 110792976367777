/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 103번째 로그에서는 Discord 방송 시도, 이제 쿠베에서 도커 못 써요? 도커 허브의 깐깐한 정책 등에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "Discord 방송 환경"), "\n", React.createElement(_components.h3, null, "이제 쿠베에서 도커 못 쓰나요?"), "\n", React.createElement(_components.h3, null, "도커 허브의 깐깐한 정책"), "\n", React.createElement(_components.h3, null, "에어팟 맥스 미리보기"), "\n", React.createElement(_components.h3, null, "소니 Alpha7C / A7S3 카메라"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
